import { template } from '@shein/common-function'
import { showBuyNow } from 'public/src/pages/goods_detail_v2/utils/buyNow.js'
import { getQueryString } from '@shein/common-function'
export default {
  namespaced: true,
  state: {
    addToBagErrorTips: '',
    addToBagLoading: false,
    showAddedText: false,
    addBtnAtmosphereInfo: [],
    checkNotSelectedTips: 0,
    oneClickPayState: {
      support: false,
      billInfo: null,
      stage: 1,
    }
  },
  getters: {
    language(state, getters, rootState) {
      return rootState.language
    },
    config(state, getters, rootState, rootGetters) {
      const {
        isCustomization,
        isCurrentSoldOut,
        isAllSoldOut,
        // isAllowAutoClaim,
      } = rootGetters
      const { IS_RW, SiteUID } = rootState
      const { hasGoodsSize, isAddWishAfterRec, isButtonFreeStyle } = getters
      return {
        showRWIntegralTips: getters.showRWIntegralTips,
        isCustomization,
        isCurrentSoldOut,
        isAllSoldOut,
        // isAllowAutoClaim,
        isAddWishAfterRec,
        hasGoodsSize,
        IS_RW,
        SiteUID,
        isButtonFreeStyle
      }
    },
    isAddWishAfterRec(state, getters, rootState, rootGetters) {
      const { screenAbtConfig } = rootGetters
      return screenAbtConfig?.wishsimilar?.p?.wishsimilar === 'show'
    },
    currentGoodsId(state, getters, rootState) {
      return rootState.currentGoodsId
    },
    skuInfo(state, getters, rootState) {
      return rootState.skuInfo
    },
    hasGoodsSize(state, getters, rootState) {
      const { saleAttrList = {} } = rootState
      return !!saleAttrList?.sku_list?.length
    },
    showRWIntegralTips(state, getters, rootState) {
      const { IS_RW, lang } = rootState
      const { rewardPoints } = getters
      return IS_RW && lang !== 'mx' && rewardPoints > 0
    },
    rewardPoints(state, getters, rootState, rootGetters) {
      let currentMallPriceInfo = {}
      const { mallCode } = rootGetters
      const { skuInfo, productIntroData } = rootState
      if (skuInfo.sku_code) {
        const { mall_price = [] } = skuInfo
        currentMallPriceInfo = mall_price.find(
          item => item.mall_code == mallCode
        )
      } else {
        const { mall_prices } = productIntroData?.detail || {}
        currentMallPriceInfo = mall_prices?.find?.(
          item => item.mall_code == mallCode
        )
      }
      return currentMallPriceInfo?.rewardPoints || 0
    },
    integralTips(state, getters) {
      const { config, language, rewardPoints } = getters
      // TODO 临时替换多语言
      let languageText = language.SHEIN_KEY_PC_22936
      if (!!~config.SiteUID.indexOf('ar')) {
        if (config.IS_RW) {
          languageText = 'اكسب حتى {0} نقطة روموي عند دفع منتجاتك.'
        } else {
          languageText = languageText.replace('XX', '{0}')
        }
      } else {
        languageText = languageText.replace('XX', '{0}').replace('SHEIN', '{1}')
      }
      return template(
        `<span class="${
          config.IS_RW ? 'num' : 'color-orange-tips'
        }">${rewardPoints}</span>`,
        config.IS_RW ? 'ROMWE' : 'SHEIN',
        languageText
      )
    },
    mallName(state, getters, rootState, rootGetters) {
      const currentMallDetail = rootGetters.currentMallDetail
      return currentMallDetail?.mall_name || ''
    },
    mallStock(state, getters, rootState, rootGetters) {
      return rootGetters.mallStock
    },
    isSoldOut(state, getters, rootState) {
      const { skuInfo, saleAttrList } = rootState
      if (skuInfo.sku_code) {
        return skuInfo.stock == 0
      } else {
        return saleAttrList.sku_list.every(_ => _.stock == 0)
      }
    },
    errorTipsCombo(state, getters) {
      const { addToBagErrorTips } = state
      const { mallName, language, mallStock, showAtmosphere, isSoldOut } = getters
      return {
        addToBagErrorTips,
        mallSoldOutTips: template(mallName, language.SHEIN_KEY_PC_21952),
        showMallSoldOutTips: mallStock == 0 && !isSoldOut,
        showAtmosphere,
      }
    },
    // !isForEstimated.value
    supportOneClickPay(state, getters, rootState, rootGetters) {
      const { oneClickPayState } = state
      const { productIntroData } = rootState
      const { isCurrentSoldOut } = rootGetters
      return (
        productIntroData?.oneClickPayAuth &&
        !isCurrentSoldOut &&
        oneClickPayState?.support
      )
    },
    oneClickPayBtnStatus(state, getters, rootState, rootGetters) {
      const { oneClickPayState } = state
      const { supportOneClickPay, currentMallDetail, skuInfo } = getters
      const { mallCode } = rootGetters
      // skc 层面的支持
      if (!supportOneClickPay) return false

      // 若存在 sku 且进行了选择，则需要比对 sku 的价格以及该 sku 在一键购信息中的库存
      const { sku_code = '', mall } = skuInfo || {}
      const { billInfo } = oneClickPayState || {}
      const {
        can_add_purchase_price: limitPrice,
        skc_inventory_list: inventoryList,
      } = billInfo || {}
      // 一键购权限信息未精确到 sku 层面，则直接展示
      if (!limitPrice || !Array.isArray(inventoryList)) {
        return true
      }

      const limitPriceNum = Number(limitPrice) || 0
      // 未选择尺码，只检测价格
      if (!sku_code) {
        const skcPrice = currentMallDetail?.salePrice?.usdAmount
        const skcPriceNum = Number(skcPrice) || 0
        if (skcPriceNum && skcPriceNum > limitPriceNum) {
          return false
        }
        return true
      }

      const { inventory_list } = inventoryList[0] || {}
      if (!Array.isArray(inventory_list)) return true
      // 取出匹配当前 sku 的一键购单元，没有匹配认为不支持
      const currentSku = inventory_list.find(item => item.sku === sku_code)
      if (!currentSku) return false
      const { is_have_inventory = 0 } = currentSku
      // 库存检测
      if (!is_have_inventory) {
        return false
      }
      // 价格检测
      const currentSkuPrice = mall?.[mallCode]?.salePrice?.usdAmount
      const currentSkuPriceNum = Number(currentSkuPrice) || 0
      if (currentSkuPriceNum && currentSkuPriceNum > limitPriceNum) {
        return false
      }

      return true
    },
    isAddBagStyle(state, getters, rootState, rootGetters) {
      const { freeShippingInfo } = rootGetters
      return freeShippingInfo?.freeShippingStyle?.isAddbag || false
    },
    isButtonFreeStyle(state, getters, rootState, rootGetters) {
      const { freeShippingInfo } = rootGetters
      return freeShippingInfo?.freeShippingStyle?.isButton || false
    },
    isShowAddOnItemPopup(state, getters, rootState, rootGetters) {
      const {  screenAbtConfig } = rootGetters
      // return true
      return screenAbtConfig?.addbagsuccess?.p?.addbagsuccess === 'allshow'
    },
    addOnItemPopupType(state, getters, rootState, rootGetters) {
      const {  screenAbtConfig } = rootGetters
      // return 'onlypromo' // ['onlypromo', 'tworec', 'onebigrec']
      return screenAbtConfig?.addbagsuccess?.p?.addbagtype || 'onlypromo'
    },
    preParams(state, getters, rootState, rootGetters){
      const { goods_id, cat_id } = rootState.productIntroData?.detail
      const isTopGoods = rootGetters.screenAbtConfig?.Gatheringorders?.p?.Gatheringorders === 'Topping'
      let params = {
        sceneId: 152, // 凑单列表值
        addOnType: 2,
        cateIds: [cat_id] // 类目ID
    
      }
      if(isTopGoods) {
        params.adp = [goods_id]
      }else{
        params.goodsIds = [goods_id] // 商品ID
      }
      return params
    },
    mainCouponCode(state, getters, rootState, rootGetters){
      const { getEstimatedInfo } = rootGetters
      const codeFromUrl = getQueryString({ key: 'mainCode' }) || ''
      // return 'udf5hj3wgg'
      return codeFromUrl || getEstimatedInfo?.optimalCoupon?.coupon_code || ''
    },
    secondaryCoupon(state, getters, rootState, rootGetters){
      const { currCouponList } = rootGetters
      const mainIdx = currCouponList.findIndex(item => item.coupon == getters.mainCouponCode)
      let list = [...currCouponList].map(item => item.coupon)
      if(mainIdx > -1){
        list.splice(mainIdx, 1)
      }
      return list
    },
    saInfo(state, getters) {
      return {
        promotion_code: getters.optimalCouponCode,
        state: 'detail_rec_coupon_add',
        activity_from: 'detail_rec_coupon_add',
        abtest: 'addnewhotJson'
      }
    },
    extraOneClickPayParams(state, getters, rootState, rootGetters) {
      const { skuInfo } = rootState
      const { mallCode } = rootGetters
      const { currentMallDetail } = getters
      const add_source = getQueryString({ key: 'add_source' }) || ''

      return {
        sku_code: skuInfo.sku_code || '',
        mall_code: mallCode,
        amount: currentMallDetail?.salePrice?.amount || '',
        add_source: add_source ? `detail_${add_source}` : '',
      }
    },
    contentCombo(state, getters, rootState, rootGetters) {
      const { isCustomization, mallCode } = rootGetters
      const { addToBagLoading, checkNotSelectedTips } = state
      const { contentText, language, extraOneClickPayParams } = getters

      return {
        mallCode,
        isCustomization,
        contentText,
        language,
        addToBagLoading,
        checkNotSelectedTips,
        extraOneClickPayParams,
      }
    },
    showAtmosphere(state, getters, rootState, rootGetters) {
      const { DETAIL_STREAM_PART } = rootState
      const { addToBagLoading, addBtnAtmosphereInfo } = state
      const { mallStock, isSoldOut } = getters
      const { isCurrentSoldOut, screenAbtConfig } = rootGetters
      if (!DETAIL_STREAM_PART.THIRD) return false
      if (addToBagLoading || isCurrentSoldOut || (mallStock == 0 && !isSoldOut))
        return false
      const whereshow = screenAbtConfig?.hotnews?.param?.whereshow
      return (
        (whereshow === 'andaddtobag' || whereshow === 'onlyaddtobag') &&
        addBtnAtmosphereInfo?.length && !rootGetters.forbidAtmosphereFlow
      )
    },
    variant(state, getters, rootState) {
      const { skuInfo = {} } = rootState
      return (
        skuInfo?.sku_sale_attr?.find?.(item => item.attr_id == 87)
          ?.attr_value_name || ''
      )
    },
    showAddWishAfterRec(state, getters, rootState) {
      const { recommendCountData } = rootState
      const { isAddWishAfterRec } = getters
      return isAddWishAfterRec && recommendCountData.addWishRecommendCount < 3
    },
    threshold(state, getters, rootState, rootGetters) {
      const { getEstimatedInfo } = rootGetters
      let threshold
      if (getEstimatedInfo) {
        if (getEstimatedInfo?.isSatisfiedBuyMultiple) {
          threshold = 6
        } else if (getEstimatedInfo.isAb) {
          threshold = 3
        } else if (getEstimatedInfo.isAbCoexist) {
          threshold = getEstimatedInfo.optimalCoupon?.satisfied ? 4 : 5
        } else {
          threshold = getEstimatedInfo.optimalCoupon?.satisfied ? 1 : 2
        }
      }
      return threshold
    },
    // 加车用到的参数
    baseInfo(state, getters, rootState, rootGetters) {
      const { skuInfo, productIntroData, quantity, quickShip, defaultMallCode } = rootState
      const { mallCode, hasNewFlag, getEstimatedInfo, trendsInfo } = rootGetters
      const { variant, threshold } = getters
      const { oneClickPayState = {} } = state
      const { billInfo } = oneClickPayState
      const {
        goods_id,
        goods_sn,
        productRelationID,
        limit_count,
        slideIndex = 0,
        mall = {},
        cat_id,
        business_model,
        store_code,
        goods_img,
        // mall_stock,
      } = productIntroData?.detail || {}
      const {
        retailPrice,
        salePrice,
        mall_tags = [],
        unit_discount,
      } = mall?.[mallCode] || {}
      return {
        goods_id,
        goods_sn,
        productRelationID,
        sku_code: skuInfo?.sku_code,
        limit_count,
        mallCode,
        slideIndex,
        usdAmount: retailPrice?.usdAmount || '',
        amount: salePrice?.amount || 0,
        quantity,
        quickShip,
        mall_tags,
        hasNewFlag,
        cat_id,
        unit_discount,
        business_model,
        store_code,
        isAb: !!getEstimatedInfo?.isAb,
        variant,
        billInfo,
        defaultMallCode,
        threshold,
        goods_img,
        trendsInfo
      }
    },
    contentTextCombo(state, getters, rootState, rootGetters) {
      const {
        isCurrentSoldOut,
        unListed,
        isCustomization,
        // isAllowAutoClaim,
      } = rootGetters
      const { language, mallStock, isSoldOut } = getters
      const { showAddedText } = state
      return {
        isCurrentSoldOut,
        unListed,
        isCustomization,
        // isAllowAutoClaim,
        isSoldOut,
        language, 
        mallStock,
        showAddedText
      }
    },
    isBuyNow(state, getters, rootState, rootGetters) {
      const { DETAIL_STREAM_PART } = rootState
      if (!DETAIL_STREAM_PART.THIRD) return false
      let salePriceUsdAmount = getters.skuInfo?.mall?.[rootGetters.mallCode]?.salePrice?.usdAmount || getters.currentMallDetail?.salePrice?.usdAmount
      const { isSupportQuickship, quickshipStrongAbt } = rootGetters
      const { supportQuickshipSence } = isSupportQuickship
      //非售罄 非一键购 非定制商品，首屏商品命中quickship,skc内任意一个sku满足qs都可以展示
      if(![rootGetters.isCurrentSoldOut, getters.oneClickPayBtnStatus, rootGetters.isCustomization ].includes(true) && supportQuickshipSence && quickshipStrongAbt.showBuynowquickship){
        return true
      }
      let { lang } = rootState
      let { isShow } = showBuyNow({
        goodsId: getters.currentGoodsId,
        DetailBuynowAbt: rootGetters.screenAbtConfig?.DetailBuynow?.p,
        isSoldOut: rootGetters.isCurrentSoldOut,
        oneClickPayStateSupport: getters.oneClickPayBtnStatus,
        isCustomization: rootGetters.isCustomization,
        tsp: rootState.productIntroData.tsp,
        isFreeShippin: rootGetters.freeShippingInfo?.isFreeShippin,
        getEstimatedInfo: rootGetters.getEstimatedInfo,
        salePriceUsdAmount,
        lang,
        parentCats: rootState.productIntroData.parentCats
      })
      return isShow
    },
    // 立即购买价格悬浮
    isDetailBuyNowPriceCeiling(state, getters, rootState, rootGetters) {
      return rootGetters.screenAbtConfig?.DetailBuynow?.p?.quickprice === 'Ceiling'
    },
    currentMallDetail(state, getters, rootState, rootGetters) {
      return rootGetters.currentMallDetail
    }
  },
  mutations: {
    updateAddToBagErrorTips(state, payload) {
      state.addToBagErrorTips = payload
    },
    updateAddToBagLoading(state, payload) {
      state.addToBagLoading = payload
    },
    updateAddBtnAtmosphereInfo(state, payload) {
      state.addBtnAtmosphereInfo = payload
    },
    updateShowAddedText(state, payload) {
      state.showAddedText = payload
    },
    updateCheckNotSelectedTips(state, payload) {
      state.checkNotSelectedTips = payload
    },
    updateOneClickPayState(state, payload) {
      state.oneClickPayState = payload
    }
  },
}
