// 这里还要挂载替换
import { createApp } from './app'
import router from '../goods_detail_v2/router/index'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { businessLibInitializeForCSR } from 'public/src/pages/common/business-lib-initialize/csr.js'
import { getPreFetchDetailRealTimeData } from 'public/src/services/pre_requests/detail'
const { app, store } = createApp()

if (window?.gbRawData) {
  if (window?.gbRawData.headerVueSsr) {
    businessLibInitializeForCSR(app, { bsLibsEnvs: window?.gbRawData.bsLibsEnvs })
  }
  app.use(infiniteScroll)
  store.replaceState({ ...store.state, ...window.gbRawData })
  
}
app.use(router)
router.isReady().then(() => {
  getPreFetchDetailRealTimeData().then((data) => {
    if (data) {
      store.dispatch('initDetailSreamPart', data)
    }
  }).finally(() => {
    app.mount('#goods-detail-v3') // vue3会多加一层div，  不会替换div
    if(typeof window !== 'undefined' && location?.search?.includes('detail_debug')) {
      window.__detail_store__ = store
    }
  })

})

